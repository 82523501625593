import React, {Component} from 'react';
import styled from 'styled-components'
import {navigate} from 'gatsby'
import iconFacebook from "images/icons/facebook-white.svg"
import iconFacebookBlack from "images/icons/facebook-black.svg"
import iconFacebookHover from "images/icons/facebook-red.svg"
import iconTwitter from "images/icons/twitter-white.svg"
import iconTwitterBlack from "images/icons/twitter-black.svg"
import iconTwitterHover from "images/icons/twitter-red.svg"
import iconInstagram from "images/icons/instagram-white.svg"
import iconInstagramBlack from "images/icons/instagram-black.svg"
import iconInstagramHover from "images/icons/instagram-red.svg"
import iconYoutube from "images/icons/youtube-white.svg"
import iconYoutubeBlack from "images/icons/youtube-black.svg"
import iconYoutubeHover from "images/icons/youtube-red.svg"
import iconEnvelop from "images/icons/envelop-white.svg"
import iconEnvelopBlack from "images/icons/envelop-black.svg"
import iconEnvelopHover from "images/icons/envelop-red.svg"
import {window} from "browser-monads";
import addToMailchimp from "gatsby-plugin-mailchimp";

const SocialUl = styled.ul`
    list-style: none;
    margin: 1rem 0 0.5rem;
    position: relative;
    z-index: 3;
    padding-left: 0;
    width: 100%;

    li {
        display: inline-block;
        width: 36px;
        text-align: center;

        .icon {
            &>div {
                width: 26px;
                height: 26px;
                display: inline-block;
                transition: all 0.2s ease-in;
            }

            &-facebook>div {
                background: url(${iconFacebook}) no-repeat center center;

                &:hover {
                    background: url(${iconFacebookHover}) no-repeat center center;
                }
            }

            &-instagram>div {
                background: url(${iconInstagram}) no-repeat center center;

                &:hover {
                    background: url(${iconInstagramHover}) no-repeat center center;
                }
            }

            &-twitter>div {
                background: url(${iconTwitter}) no-repeat center center;

                &:hover {
                    background: url(${iconTwitterHover}) no-repeat center center;
                }
            }

            &-youtube>div {
                background: url(${iconYoutube}) no-repeat center center;

                &:hover {
                    background: url(${iconYoutubeHover}) no-repeat center center;
                }
            }
            
            &-envelop {
                background: none;
                border: none;
            }
            
            &-envelop>div {
                background: url(${iconEnvelop}) no-repeat center center;

                &:hover {
                    background: url(${iconEnvelopHover}) no-repeat center center;
                }
            }
        }
    }
    
    &.inverse{
        li .icon {
          &-facebook>div {
                background: url(${iconFacebookBlack}) no-repeat center center;

                &:hover {
                    background: url(${iconFacebook}) no-repeat center center;
                }
            }

            &-instagram>div {
                background: url(${iconInstagramBlack}) no-repeat center center;

                &:hover {
                    background: url(${iconInstagram}) no-repeat center center;
                }
            }

            &-twitter>div {
                background: url(${iconTwitterBlack}) no-repeat center center;

                &:hover {
                    background: url(${iconTwitter}) no-repeat center center;
                }
            }

            &-youtube>div {
                background: url(${iconYoutubeBlack}) no-repeat center center;

                &:hover {
                    background: url(${iconYoutube}) no-repeat center center;
                }
            }
            
            &-envelop {
                background: none;
                border: none;
            }
            
            &-envelop>div {
                background: url(${iconEnvelopBlack}) no-repeat center center;

                &:hover {
                    background: url(${iconEnvelop}) no-repeat center center;
                }
            }
        }
    }
`

class Social extends Component {
    openSignupModal = () => {
        if (typeof window.tx !== 'undefined') {
            window.tx({
                widgetId: 'TKaMBlondon',
                configId: '3b435da5',
                type: 'onboarding',
                onDismiss: (obj) => {
                    if (obj) {
                        const listFields = {
                            'NAME': obj.user.name,
                            'REASON': obj.selected.questions["What is your main reason for seeing ‘To Kill a Mockingbird’?"][0],
                            'GOWITH': obj.selected.questions["Who are you<br />planning to go with?"][0],
                            'MARKETING': typeof obj.selected.terms["I would like to opt-in to hear about productions from the producers of ‘To Kill a Mockingbird’"] !== 'undefined' ? "Yes" : "No"
                        }
                        // const listFields = {
                        //     'NAME': obj.user.name,
                        //     'REASON': obj.selected.questions.shows[0],
                        //     'GOWITH': obj.selected.questions.who_with[0],
                        //     'MARKETING': typeof obj.selected.terms.show !== 'undefined' ? "Yes" : "No"
                        // }


                        addToMailchimp(obj.user.email, listFields) // listFields are optional if you are only capturing the email address.
                            .then(data => {
                                console.log(data)
                            })
                        navigate(
                            "/thank-you/",
                            {
                                state: {
                                    thankYou: true,
                                    name: obj.user.name
                                },
                            }
                        )
                    }
                },
                onFormComplete: (user) => {
                    window.gtag = window.gtag || [];
                    window.gtag('event', 'conversion', {'send_to': 'AW-699463522/Ei_hCILdvrEBEOLuw80C'});
                }
            })
        }
    }
    render() {
        return (
            <SocialUl className={`${this.props.inverse ? "inverse" : ''}`}>
                <li>
                    <a href="http://www.facebook.com/mockingbirdwestend" target="_blank"
                       rel="noopener noreferrer" className="icon icon-facebook">
                        <div/>
                        <span className="sr-only">Facebook</span>
                    </a>
                </li>
                <li>
                    <a href="https://www.instagram.com/mockingbirdwestend" target="_blank"
                       rel="noopener noreferrer" className="icon icon-instagram">
                        <div/>
                        <span className="sr-only">Instagram</span>
                    </a>
                </li>
                <li>
                    <a href="https://twitter.com/mockingbirdUK" target="_blank"
                       rel="noopener noreferrer" className="icon icon-twitter">
                        <div/>
                        <span className="sr-only">Twitter</span>
                    </a>
                </li>
                <li>
                    <a href="https://www.youtube.com/channel/UCnv6VBmy_N1EoYNnuJ7vaDg" target="_blank"
                       rel="noopener noreferrer" className="icon icon-youtube">
                        <div/>
                        <span className="sr-only">Youtube</span>
                    </a>
                </li>
                <li>
                    <button onClick={this.openSignupModal}
                            className="icon icon-envelop">
                        <span className="sr-only">Sign up</span>
                        <div/>
                    </button>
                </li>
            </SocialUl>
        );
    }
}

export default Social;