import ClarendonW01LightEOT from "./fonts/a9f8f59e-3a21-4ddc-9d78-92ded8059834.eot?#iefix";
import ClarendonW01LightWOFF2 from "./fonts/6a55dca3-5d6f-4c7b-8beb-41df036bd33e.woff2";
import ClarendonW01LightWOFF from "./fonts/b12f1ce1-c1c0-415b-a7dc-fefbde9ab77b.woff";
import ClarendonW01LightTTF from "./fonts/bc8a59c9-046a-4969-b03a-b601ef3a7730.ttf";

import ClarendonW01MediumEOT from "./fonts/8f0debf7-3070-4e07-a7bc-9a51f62360c4.eot?#iefix";
import ClarendonW01MediumWOFF2 from "./fonts/ce0638f5-d199-4ead-aa72-acf8a8d7e98b.woff2";
import ClarendonW01MediumWOFF from "./fonts/4602060e-0fca-49ab-8f93-c5867095ba8f.woff";
import ClarendonW01MediumTTF from "./fonts/0c016494-9059-4dab-bcb6-1c29d701590b.ttf";

import ClarendonW01BoldEOT from "./fonts/b8561208-8cf7-41b5-9671-ed82930b186d.eot?#iefix";
import ClarendonW01BoldWOFF2 from "./fonts/85bf2ab6-fe79-4629-b7d7-58598c4fec98.woff2";
import ClarendonW01BoldWOFF from "./fonts/9a8de9b4-b40a-45b9-9e87-5f8b8c9ff798.woff";
import ClarendonW01BoldTTF from "./fonts/448c8d9a-938d-4e6b-96fe-07294d6dabed.ttf";

import ClarendonUrwNarrowLightEOT from "./fonts/clarendonurwnar-lig-webfont.eot?#iefix";
import ClarendonUrwNarrowLightWOFF2 from "./fonts/clarendonurwnar-lig-webfont.woff2";
import ClarendonUrwNarrowLightWOFF from "./fonts/clarendonurwnar-lig-webfont.woff";
import ClarendonUrwNarrowLightTTF from "./fonts/clarendonurwnar-lig-webfont.ttf";

export default {
    ClarendonW01LightEOT,
    ClarendonW01LightWOFF2,
    ClarendonW01LightWOFF,
    ClarendonW01LightTTF,

    ClarendonW01MediumEOT,
    ClarendonW01MediumWOFF2,
    ClarendonW01MediumWOFF,
    ClarendonW01MediumTTF,

    ClarendonW01BoldEOT,
    ClarendonW01BoldWOFF2,
    ClarendonW01BoldWOFF,
    ClarendonW01BoldTTF,

    ClarendonUrwNarrowLightEOT,
    ClarendonUrwNarrowLightWOFF2,
    ClarendonUrwNarrowLightWOFF,
    ClarendonUrwNarrowLightTTF
};