// import { media } from "utils/Media"
// match bootstrap sizes, also add xxl
const sizes = {
    xs: "0px",
    extraSmallMob: '375px',
    extraLargeMob: '414px',
    sm: "576px",
    md: "768px",
    lg: "992px",
    xl: "1200px",
    xsl: "1300px",
    xxl: "1400px",
    xxxl: "1600px"
};

const fontSizeBase = 1;

const font = {
    family: {
        special: "clarendon_urw_narrowlight, Helvetica, Arial, sans-serif",
        light: "clarendon_urw_narrowlight, Helvetica, Arial, sans-serif",
        base: "Clarendon W01 Medium, Helvetica, Arial, sans-serif",
        medium: "Clarendon W01 Medium, Helvetica, Arial, sans-serif",
        bold: "Clarendon W01 Bold, Helvetica, Arial, sans-serif",
        body: "Clarendon W01 Medium, Helvetica, Arial, sans-serif"
    },
    size: {
        base: `${fontSizeBase * .875}rem`, // 14px
        sm: `${fontSizeBase * .75}rem`, // 12px
        md: `${fontSizeBase}rem`, // 16px
        lg: `${fontSizeBase * 1.25}rem`, // 20px
        xl: `${fontSizeBase * 1.5}rem` // 24px
    },
    lineHeight: {
        sm: 1.1,
        base: 1.4,
        headings: 1.2
    },
    h1: {
        size: `${fontSizeBase * 3}rem`,
        lineHeight: `100%`,
        md: {
            size: `${fontSizeBase * 2}rem`,
            lineHeight: `100%`,
        },
        sm: {
            size: `${fontSizeBase * 1.125}rem`,
            lineHeight: `100%`,
        }
    },
    h2: {
        size: `${fontSizeBase * 1.5}rem`,
        lineHeight: `100%`,
        sm: {
            size: `${fontSizeBase * 1.125}rem`,
            lineHeight: `${fontSizeBase * 1.5}rem`,
        },
        xs: {
            size: `${fontSizeBase * 1.125}rem`,
            lineHeight: `${fontSizeBase * 1.5}rem`,
        },
    },
    h3: {
        size: `${fontSizeBase * 1.5}rem`,
        lineHeight: `100%`,
        sm: {
            size: `${fontSizeBase * 1.125}rem`,
            lineHeight: `${fontSizeBase * 1.5}rem`,
        },
        xs: {
            size: `${fontSizeBase * 1.125}rem`,
            lineHeight: `${fontSizeBase * 1.5}rem`,
        },
    },
    h4: {
        size: `${fontSizeBase * 1.5}rem`,
        lineHeight: `${fontSizeBase * 1.8125}rem`,
        sm: {
            size: `${fontSizeBase * 1.125}rem`,
            lineHeight: `${fontSizeBase * 1.25}rem`,
        }
    },
    h5: {
        size: `${fontSizeBase * 1.25}rem`,
        lineHeight: `${fontSizeBase * 1.5}rem`,
        sm: {
            size: `${fontSizeBase * 1.125}rem`,
            lineHeight: `${fontSizeBase * 1.375}rem`,
        }
    },
    h6: {
        size: `${fontSizeBase * 1.25}rem`,
        lineHeight: `${fontSizeBase * 1.5}rem`,
        sm: {
            size: `${fontSizeBase * 1.125}rem`,
            lineHeight: `${fontSizeBase * 1.375}rem`,
        }
    },
    p: {
        size: `${fontSizeBase * 0.875}rem`,
        lineHeight: `150%`,
        sm: {
            size: `${fontSizeBase}rem`,
            lineHeight: `${fontSizeBase * 1.25}rem`,
        },
        bigger: {
            size: `${fontSizeBase * 1.125}rem`,
            lineHeight: `130%`,
        }
    }
}

export default {
    sizes,
    font,
    transitionBase: "all .4s ease-in-out",
    colors: {
        white: "#ffffff",
        black: "#000000",
        brown: "#b39e69",
        superLightGrey: "#FAFAFA",
        lightGrey: "#F2F2F2",
        grey: "#A6B1C1",
        darkGrey: "#CCCCCC",
        red: "#ff3430",
        pink: "#C9599C",
        orange: "#E78239",
        yellow: "#fff8d1",
        purple: "#A57CFC",
        blue: "#a1b1c1",
        green: "#89C973"
    }
};