import {createGlobalStyle, withTheme} from "styled-components";
import fontFiles from "./Fonts";
import {media} from "utils/Media"

const GlobalStyles = createGlobalStyle`
    @font-face {
        font-family: "Clarendon W01 Light";
        src: url(${fontFiles.ClarendonW01LightEOT});
        src:
            url(${fontFiles.ClarendonW01LightEOT}) format("eot"), 
            url(${fontFiles.ClarendonW01LightWOFF2}) format("woff2"), 
            url(${fontFiles.ClarendonW01LightWOFF}) format("woff"),
            url(${fontFiles.ClarendonW01LightTTF}) format("truetype");
        font-display: swap;
    }

    @font-face {
        font-family: "Clarendon W01 Medium";
        src: url(${fontFiles.ClarendonW01MediumEOT});
        src:
            url(${fontFiles.ClarendonW01MediumEOT}) format("eot"), 
            url(${fontFiles.ClarendonW01MediumWOFF2}) format("woff2"), 
            url(${fontFiles.ClarendonW01MediumWOFF}) format("woff"),
            url(${fontFiles.ClarendonW01MediumTTF}) format("truetype");
        font-display: swap;
    }

    @font-face {
        font-family: "Clarendon W01 Bold";
        src: url(${fontFiles.ClarendonW01BoldEOT});
        src:
            url(${fontFiles.ClarendonW01BoldEOT}) format("eot"), 
            url(${fontFiles.ClarendonW01BoldWOFF2}) format("woff2"), 
            url(${fontFiles.ClarendonW01BoldWOFF}) format("woff"),
            url(${fontFiles.ClarendonW01BoldTTF}) format("truetype");
        font-display: swap;
    }


    @font-face {
        font-family: "clarendon_urw_narrowlight";
        src: url(${fontFiles.ClarendonUrwNarrowLightEOT});
        src:
            url(${fontFiles.ClarendonUrwNarrowLightEOT}) format("eot"), 
            url(${fontFiles.ClarendonUrwNarrowLightWOFF2}) format("woff2"), 
            url(${fontFiles.ClarendonUrwNarrowLightWOFF}) format("woff"),
            url(${fontFiles.ClarendonUrwNarrowLightTTF}) format("truetype");
        font-display: swap;
    }


    body {
        font-family: ${props => props.theme.font.family.body};
        font-size: ${props => props.theme.font.size.base};
        font-weight: normal;
        line-height: ${props => props.theme.font.lineHeight.base};
        color: ${props => props.theme.colors.greyDark};
        -webkit-font-smoothing: antialiased;
        overflow-x: hidden;
    }
    
    h1,
    h2, 
    h3 {
        font-family: ${props => props.theme.font.family.light};
        font-weight: 300;
        margin-bottom: 1rem;
    }
    
    h4, 
    h5, 
    h6 {
        font-family: ${props => props.theme.font.family.base};
        font-weight: normal;
    }

    h1, .h1 {
        font-size: ${props => props.theme.font.h1.sm.size};
        line-height: ${props => props.theme.font.h1.sm.lineHeight};
        
        @media ${media.md} {
            font-size: ${props => props.theme.font.h1.md.size};
            line-height: ${props => props.theme.font.h1.md.lineHeight};
        }
        
        @media ${media.lg} {
            font-size: ${props => props.theme.font.h1.size};
            line-height: ${props => props.theme.font.h1.lineHeight};
        }
    }
    h2 {
        font-size: ${props => props.theme.font.h2.xs.size};
        line-height: ${props => props.theme.font.h2.xs.lineHeight};
        letter-spacing: 0.03em;
        
        @media ${media.md} {
            font-size: ${props => props.theme.font.h2.size};
            line-height: ${props => props.theme.font.h2.lineHeight};
        }
    }
    h3 {
        font-size: ${props => props.theme.font.h3.xs.size};
        line-height: ${props => props.theme.font.h3.xs.lineHeight};
        
        @media ${media.sm} {
            font-size: ${props => props.theme.font.h3.sm.size};
            line-height: ${props => props.theme.font.h3.sm.lineHeight};
        }
        
        @media ${media.lg} {
            font-size: ${props => props.theme.font.h3.size};
            line-height: ${props => props.theme.font.h3.lineHeight};
        }
    }
    h4 {
        font-size: ${props => props.theme.font.h4.sm.size};
        line-height: ${props => props.theme.font.h4.sm.lineHeight};
        
        @media ${media.lg} {
            font-size: ${props => props.theme.font.h4.size};
            line-height: ${props => props.theme.font.h4.lineHeight};
        }
    }
    h5 {
        font-size: ${props => props.theme.font.h5.sm.size};
        line-height: ${props => props.theme.font.h5.sm.lineHeight};
        
        @media ${media.lg} {
            font-size: ${props => props.theme.font.h5.size};
            line-height: ${props => props.theme.font.h5.lineHeight};
        }
    }
    h6 {
        font-size: ${props => props.theme.font.h6.sm.size};
        line-height: ${props => props.theme.font.h6.sm.lineHeight};
        
        @media ${media.lg} {
            font-size: ${props => props.theme.font.h6.size};
            line-height: ${props => props.theme.font.h6.lineHeight};
        }
    }

    a {
        color: ${props => props.theme.colors.black};
        &:hover {
            color: ${props => props.theme.colors.black};  
        }
        
        &.link--white {
            color: ${props => props.theme.colors.white};
            &:hover {
                color: ${props => props.theme.colors.white};  
            }
        }
    }
    
    p,ul,ol,span {
        font-size: ${props => props.theme.font.p.size};
        line-height: ${props => props.theme.font.p.lineHeight};
        font-family: ${props => props.theme.font.family.light};
        font-weight: 300;
        
        @media ${media.sm} {
            font-size: ${props => props.theme.font.p.sm.size};
            line-height: ${props => props.theme.font.p.sm.lineHeight};
        }
        
        &.bigger {
            font-size: ${props => props.theme.font.p.bigger.size};
            line-height: ${props => props.theme.font.p.bigger.lineHeight};
        }
        
        &.bold {
            font-family: ${props => props.theme.font.family.bold};
            font-weight: bold;
        }
    }

    ul {
        padding-left: 20px;
    }

    img {
        max-width: 100%;
    }
`;

export default withTheme(GlobalStyles);