import React, { Component } from "react"
import PropTypes from "prop-types"
import styled, { ThemeProvider } from "styled-components"
import 'scss/main.scss'
import SEO from "components/Seo/Seo"
import "scss/bootstrap.scss"
import GlobalStyles from "utils/GlobalStyles";
import theme from "utils/Theme"
import {window} from "browser-monads";
import Cookies from 'universal-cookie'
import Header from "./Header";

const cookies = new Cookies();
const COOKIE_DOMAIN = "tokillamockingbird.co.uk";
let expiry = new Date();
expiry = new Date(expiry.setMonth(expiry.getMonth() + 3))

class Layout extends Component {
	componentDidMount() {
		const urlParams = new URLSearchParams(window.location.search);
		let audience = 'not set'

		if (urlParams.get('audience') !== null) {
			if (typeof cookies.get('audience') === 'undefined') {
				// Decide audience by the url string
				if (urlParams.get('audience') === 'drama') {
					audience = 'dramaTKAM'
				} else if (urlParams.get('audience') === 'politics') {
					audience = 'politicsTKAM'
				} else if (urlParams.get('audience') === 'literature') {
					audience = 'literatureTKAM'
				} else if (urlParams.get('audience') === 'other') {
					audience = 'otherTKAM'
				} else if (urlParams.get('audience') === 'drama_sfp') {
					audience = 'dramaSFP'
				} else if (urlParams.get('audience') === 'drama_dmt') {
					audience = 'dramaDMT'
				} else {
					audience = urlParams.get('audience')
				}

				cookies.set('audience', audience, {
					expires: expiry,
					domain: COOKIE_DOMAIN
				});
			}

		} else {
			if (typeof cookies.get('audience') === 'undefined') {
				// Let Lotame decide the audience
				if (typeof window.lotame !== 'undefined') {
					const audienceList = [
						'dramaTKAM',
						'literatureTKAM',
						'politicsTKAM'
					]

					let appliedAudiences = []

					Object.keys(window.lotame.Profile.Audiences.Audience).map(key => {
						const abbr = window.lotame.Profile.Audiences.Audience[key].abbr;
						if (audienceList.indexOf(abbr) > -1) {
							appliedAudiences.push(abbr)
						}
						return true
					});

					if (appliedAudiences.includes('politicsTKAM') && !appliedAudiences.includes('literatureTKAM') && !appliedAudiences.includes('dramaTKAM')) {
						audience = "politicsTKAM-Lotame"
					} else if (!appliedAudiences.includes('politicsTKAM') && appliedAudiences.includes('literatureTKAM') && !appliedAudiences.includes('dramaTKAM')) {
						audience = "literatureTKAM-Lotame"
					} else if (!appliedAudiences.includes('politicsTKAM') && !appliedAudiences.includes('literatureTKAM') && appliedAudiences.includes('dramaTKAM')) {
						audience = "dramaTKAM-Lotame"
					} else if (appliedAudiences.includes('politicsTKAM') && appliedAudiences.includes('literatureTKAM') && !appliedAudiences.includes('dramaTKAM')) {
						audience = "politicsTKAM-Lotame"
					} else {
						audience = "dramaTKAM-Lotame"
					}
				}
			}
		}

		window.dataLayer = window.dataLayer || [];
		window.dataLayer.push({
			'event':'setAudience',
			'audience' : audience,
			'nonInteraction': 'True'
		});

		const header = document.getElementById('header')
		let headerHeight = header.clientHeight
		document.body.style.paddingTop = `${headerHeight}px`
		window.addEventListener('resize', () => {
		  headerHeight = header.clientHeight
		  document.body.style.paddingTop = `${headerHeight}px`
		})
	}

	componentWillUnmount() {
		cookies.set('history', this.props.page, {
			expires: expiry,
			domain: COOKIE_DOMAIN
		});
	}

	render() {
		const {title, data, description, slug} = this.props
		return(
			<ThemeProvider theme={theme}>
				<div id="wrapper">
					<SEO
						title={title}
						description={description ? description : data.metaDescription}
						keywords={[]}
						slug={slug}
					/>
					<GlobalStyles />
					<noscript className="no-js">Javascript is required to view the full experience of this site.</noscript>

					<Header page={this.props.page}/>
					<main id="main">{this.props.children}</main>
				</div>
			</ThemeProvider>
		)
	}
}

Layout.propTypes = {
    children: PropTypes.node.isRequired,
}

Layout.defaultProps = {
	data: {
		metaDescription: "To Kill a Mockingbird played at the Gielgud Theatre, London. A play by Aaron Sorkin. Directed by Bartlett Sher. Thank you London!",
		metaTitle: "To Kill a Mockingbird London | Thank you London!",
	},
};

export default Layout