import React, {Component} from 'react';
import {Link, navigate} from 'gatsby'
import styled from 'styled-components'
import {media} from "utils/Media"
import Social from "components/shared/Social"
import {Dropdown, DropdownToggle, DropdownMenu, DropdownItem} from "reactstrap"
import {window} from "browser-monads";
import addToMailchimp from "gatsby-plugin-mailchimp";

const Wrapper = styled.div`
.coronaMsg {
        z-index: 10000;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0,0,0,0.8);
        font-family: arial, helvetica, sans-serif;
        font-size: 16px;
        line-height: normal;
        text-align: center;
    
        &--content {
            position: relative;
            margin: 1rem auto 0;
            width: 90%;
            max-width: 600px;
            padding: 2rem 1rem 1rem;
            background-color: #b39d6b;
            color: black;
            text-align: left;
            
            p {
                font-size: 1.15rem;
            }
        }
    
        &--closeBtn {
            cursor: pointer;
            display: block;
            position: absolute;
            font-weight: bold;
            width: 44px;
            height: 44px;
            text-align: right;
            font-size: 20px;
            color: black;
            top: 6px;
            right: 10px;
        }
    }
.header {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 501;
    transition: all .2s ease-in-out;
    padding: 0;
    height: 51px;
    background-color: ${props => props.theme.colors.blue};
    
    &.index {
        height: 51px;
        @media ${media.sm} {
            height: 51px;
        }
    }

    &__container {
        height: 100%;
        max-width: 1920px;
        width: 100%;
        padding: 0;
        margin: 0 auto;
        position: relative;
    }

    // Header smaller
    &.collapsed {
        transition: all .2s ease-in-out;
    }

    &__social-wrapper {
        position: absolute;
        top: -5px;
        left: 50px;
        z-index: 502;
        display: none;
        
        @media ${media.md} {
            display: block;
        }

        .icon > div {
            margin: 0 0 0 3px;
        }
    }
}
.nav {
    transition: all .2s ease-in-out;
    position: fixed;
    display: block;
    top: 0;
    left: 0;
    z-index: 500;
    width: 100%;
    max-width: initial;
    transform: translateX(-100%);
    height: 100vh;
    background: rgba(0, 0, 0, 0.8);

    @media ${media.md} {
        width: 300px;
    }

    &__helper {
        height: 100%;
        min-height: 460px;
        overflow-y: auto;
        position: relative;
    }

    > div {
        width: 100%;
    }

    &__links {
        width: 100%;
        overflow-x: hidden;
        overflow-y: auto;
        margin: 51px 0 0;
        padding: 0;
        height: calc(100vh - 90px);

        li {
            height: 50px;
            text-align: left;
            display: flex;
            align-items: center;
            //justify-content: center;
            padding: 0 1rem;
            position: relative;

            .link {
                font-size: 1.25rem;
                font-family:"Clarendon W01 Light", Arial, sans-serif;
                font-weight: 300;
                text-decoration: none;
                color: ${props => props.theme.colors.yellow};
                display: block;
                transition: all 100ms linear;
                text-transform: uppercase;
                
                @media ${media.md} {
                    font-size: 1.5rem;
                }

                &:hover, &.active {
                    color: ${props => props.theme.colors.white};

                    .link--hover {
                        display: block;
                    }

                    &::after {
                        opacity: 1;
                    }
                }
            }
        }
    }

    &.active {
        transform: translateX(0);
    }
}

.nav-toggle {
    position: relative;
    z-index: 501;
    height: 40px;
    width: 42px;
    border: 0;
    transition: all .2s ease-in-out;
    cursor: pointer;
    padding: 5px;
    background-color: ${props => props.theme.colors.blue};
    margin-left: 14px;
    margin-top: 5px;

    &__burger {
        width: 34px;
        height: 4px;
        position: absolute;
        transform: translateY(-50%);
        border-radius: 3px;
        transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1),opacity 0.55s ease;
        background-color: ${props => props.theme.colors.black};
        left: 0;
        
        &:nth-child(1) {
            top: 11px;
        }
        
        &:nth-child(2) {
            top: 20px;
        }
        
        &:nth-child(3) {
            top: 29px;
        }
    }
    
    &.active {
        .nav-toggle__burger:nth-child(1) {
            transform: rotate(45deg) translate(2px, 8px);
        }
        
        .nav-toggle__burger:nth-child(2) {
            opacity: 0;
            transform: rotate(0deg) scale(0.2, 0.2);
        }
        
        .nav-toggle__burger:nth-child(3) {
            transform: rotate(-45deg) translate(5px, -11px);     
        }
    }
}
`

const CovidBtn = styled.button`
    //width: 107px;
    height: 29px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border: 2px solid ${props => props.theme.colors.black};
    //display: none;
    text-transform: uppercase;
    padding: 2px 10px;
    background: none;
    
    position: relative;
    top: initial;
    right: initial;
    margin: 3px auto 0;
    width: 80%;
    
    @media ${media.sm} {
        position: absolute;
        height: 36px;
        right: 330px;
        top: 8px;
        margin: 0;
        width: initial;
    }
    
    @media ${media.md} {
        right: 350px;
    }
    
    span {
        font-family: "Clarendon W01 Bold", Arial, sans-serif;
        font-weight: bold;
        font-size: 0.9375rem;
        
        @media ${media.md} {
            font-size: 1rem;
        }
    }
`
const TicketBtn = styled(Link)`
    width: 107px;
    height: 29px;
    position: absolute;
    background-color: ${props => props.theme.colors.black};
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2px;
    right: 120px;
    top: 12px;
    cursor: pointer;
    //display: none;
    
    @media ${media.sm} {
        width: 140px;
        height: 36px;
        right: 180px;
        top: 8px;
    }
    
    @media ${media.md} {
        width: 160px;
        right: 180px;
    }
    
    span {
        color: ${props => props.theme.colors.yellow};
        text-decoration: none;
        font-size: 1rem;
        text-transform: uppercase;
        font-family: "Clarendon W01 Bold", Arial, sans-serif;
        font-weight: bold;
        
        @media ${media.sm} {
            font-size: 1.5rem;
        }
        
        @media ${media.md} {
            font-size: 1.75rem;
        }
    }
`

const TourDropdown = styled(Dropdown)`
    height: 36px;
    width: 102px;
    position: absolute;
    right: 10px;
    top: 6px;
    
    @media ${media.sm} {
        height: 36px;
        width: 161px;
        top: 6px;
    }
    
    .dropdown-toggle {
        background-color: ${props => props.theme.colors.blue};
        height: 29px;
        width: 108px;
        font-size: 0.75rem;
        border: 3px solid ${props => props.theme.colors.black};
        padding: 5px;
        font-family: "Clarendon W01 Bold", Arial, sans-serif;
        font-weight: bold;
        text-transform: uppercase;
        button {
            color: #000!important;
        }
        @media ${media.sm} {
            height: 40px;
            width: 161px;
            padding: 6px;
            font-size: 1rem;
        }
    }
    
    .dropdown-menu {
        margin: -4px 0 0 0;
        border-radius: 0;
        border-top: none;
        border-left: 3px solid ${props => props.theme.colors.black};
        border-right: 3px solid ${props => props.theme.colors.black};
        border-bottom: 3px solid ${props => props.theme.colors.black};
        background-color: ${props => props.theme.colors.blue};
        min-width: initial;
        width: 108px;
        text-align: center;
        left: 1px !important;
        
        @media ${media.sm} {
            width: 161px;
            margin: -8px 0 0 0;
            left: 0 !important;
        }
    }
    
    .dropdown-item {
        padding: 0.4rem 6px 0.4rem 0;
        font-size: 0.75rem;
        
        @media ${media.sm} {
          font-size: 1rem;
        }
        
        &:hover {
            background-color: ${props => props.theme.colors.blue};
        }
        
        a {
            font-family: "Clarendon W01 Bold", Arial, sans-serif;
            font-weight: bold;
            text-transform: uppercase;
            text-decoration: none;
            
            &:hover {
                color: ${props => props.theme.colors.yellow};
            }
        }
    }
`

class Header extends Component {
    constructor(props) {
        super(props);

        this.state = {
            navToggled: false,
            collapsed: false,
            isOpen: false,
            coronaMessageOpen: false,
        };
    }

    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll, true);
        window.addEventListener('resize', this.handleScroll, true);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll, true);
        window.removeEventListener('resize', this.handleScroll, true);
    }

    toggleCoronaMessage = () => {
        this.setState({
            coronaMessageOpen: !this.state.coronaMessageOpen
        })
    }

    openSignupModal = () => {
        if (typeof window.tx !== 'undefined') {
            window.tx({
                widgetId: 'TKaMBlondon',
                configId: '3b435da5',
                type: 'onboarding',
                onDismiss: (obj) => {
                    if (obj) {
                        const listFields = {
                            'NAME': obj.user.name,
                            'REASON': obj.selected.questions["What is your main reason for seeing ‘To Kill a Mockingbird’?"][0],
                            'GOWITH': obj.selected.questions["Who are you<br />planning to go with?"][0],
                            'MARKETING': typeof obj.selected.terms["I would like to opt-in to hear about productions from the producers of ‘To Kill a Mockingbird’"] !== 'undefined' ? "Yes" : "No"
                        }
                        // const listFields = {
                        //     'NAME': obj.user.name,
                        //     'REASON': obj.selected.questions.shows[0],
                        //     'GOWITH': obj.selected.questions.who_with[0],
                        //     'MARKETING': typeof obj.selected.terms.show !== 'undefined' ? "Yes" : "No"
                        // }


                        addToMailchimp(obj.user.email, listFields) // listFields are optional if you are only capturing the email address.
                            .then(data => {
                                console.log(data)
                            })
                        navigate(
                            "/thank-you/",
                            {
                                state: {
                                    thankYou: true,
                                    name: obj.user.name
                                },
                            }
                        )
                    }
                },
                onFormComplete: (user) => {
                    window.gtag = window.gtag || [];
                    window.gtag('event', 'conversion', {'send_to': 'AW-699463522/Ei_hCILdvrEBEOLuw80C'});
                }
            })
        }
    }

    handleScroll = () => {
        // let scrollPosition = document.documentElement.scrollTop
        let scrollPosition = Math.max(window.pageYOffset, document.documentElement.scrollTop, document.body.scrollTop);
        let collapsed = scrollPosition < 20 ? false : true;
        this.setState({
            collapsed
        });
    };

    toggleNav = () => {
        this.setState({
            navToggled: !this.state.navToggled
        });
    };

    handleOpen = () => {
        this.setState({
            isOpen: !this.state.isOpen
        });
    }

    render() {
        const {navToggled, collapsed} = this.state;
        return (
            <Wrapper>
                <header id="header" className={`header ${navToggled ? 'active' : ''} ${collapsed ? 'collapsed' : ''} ${this.props.page}`}>
                    <div className="header__container">
                        {/*<button*/}
                        {/*    className={`nav-toggle ${navToggled ? 'active' : ''}`}*/}
                        {/*    onClick={this.toggleNav}*/}
                        {/*    aria-expanded="false"*/}
                        {/*    aria-controls="navbar"*/}
                        {/*    type="button"*/}
                        {/*    aria-label="Toggle navigation"*/}
                        {/*>*/}
                        {/*    <span className="nav-toggle__burger"/>*/}
                        {/*    <span className="nav-toggle__burger"/>*/}
                        {/*    <span className="nav-toggle__burger"/>*/}
                        {/*</button>*/}
                        {/*{this.props.page === 'index' &&*/}
                        {/*    <CovidBtn onClick={this.toggleCoronaMessage}>*/}
                        {/*        <span>COVID-19 update</span>*/}
                        {/*    </CovidBtn>*/}
                        {/*}*/}

                        {/*<TicketBtn to="/tickets/">*/}
                        {/*    <span>Tickets</span>*/}
                        {/*</TicketBtn>*/}

                        <TourDropdown
                            isOpen={this.state.isOpen}
                            toggle={this.handleOpen}
                        >
                            <DropdownToggle caret>
                                London
                            </DropdownToggle>
                            <DropdownMenu>
                                <DropdownItem>
                                    <a href="https://tokillamockingbirdbroadway.com/" target="_blank" rel="noreferrer noopener">New York</a>
                                </DropdownItem>
                                <DropdownItem>
                                    <a href="https://tokillamockingbirdbroadway.com/#tour" target="_blank" rel="noreferrer noopener">US Tour</a>
                                </DropdownItem>
                            </DropdownMenu>
                        </TourDropdown>

                        <div className={`header__social-wrapper`}>
                            <Social inverse/>
                        </div>
                    </div>
                </header>

                <nav id="navbar"
                     className={`nav ${navToggled ? 'active' : ''} ${collapsed ? 'collapsed' : ''}`}>
                    <div className="nav__helper">
                        <ul className="nav__links">
                            <li>
                                <Link title="Home" to="/" activeClassName="active"
                                      className="link link--home">HOME</Link>
                            </li>
                            <li>
                                <Link title="Tickets" to="/tickets/" activeClassName="active"
                                      className="link link--ticket">TICKETS</Link>
                            </li>
                            <li>
                                <Link title="News" to="/news/" activeClassName="active"
                                      className="link link--news">NEWS</Link>
                            </li>
                            <li>
                                <Link title="Work" to="/work-with-us/" activeClassName="active"
                                      className="link link--news">WORK WITH US</Link>
                            </li>
                            <li>
                                <Link title="Bios" to="/bios/" activeClassName="active" className="link link--cast">BIOS</Link>
                            </li>
                            <li>
                                <Link title="Bios" to="/videos/" activeClassName="active" className="link link--cast">Videos</Link>
                            </li>
                            <li>
                                <Link title="Bios" to="/photography/" activeClassName="active" className="link link--cast">Photography</Link>
                            </li>
                            <li>
                                <Link title="Bios" to="/education/" activeClassName="active" className="link link--education">Education</Link>
                            </li>
                        </ul>
                    </div>
                </nav>
                <div className={`coronaMsg ${!this.state.coronaMessageOpen && 'd-none'}`}>
                    <div className="coronaMsg--content">
                        <span className="coronaMsg--closeBtn" role="button" tabIndex={0} onKeyPress={this.toggleCoronaMessage} onClick={this.toggleCoronaMessage}>X</span>
                        <p>
                            Advice regarding Coronavirus / COVID-19<br/>
                        </p>
                        
                        <p>
                            Due to ongoing advice from the government regarding the spread of Coronavirus and the inevitable knock-on effect this has had on the show’s production timetable, all performances of TO KILL A MOCKINGBIRD at the Gielgud Theatre have been regretfully postponed from 21 May and will be rescheduled.
                        </p>

                        <p>
                            If you are a ticket holder you do not need to do anything for the moment as your ticket provider will be in contact. <a href="https://www.delfontmackintosh.co.uk/news/coronavirus-covid-19-update.php" target="_blank" rel="noreferrer nopener">Click here</a> for further information. 
                        </p>
                    </div>
                </div>
            </Wrapper>
        );
    }
}

export default Header;